import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { siteOrigin, googleSiteVerification } from './config';

function SEO( { lang, meta, title, description, image } ) {

    const { site } = useStaticQuery(
        graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
    );

    const metaDescription = description || site.siteMetadata.description;

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={`%s | ${site.siteMetadata.title}`}
            meta={[
                {
                    name: 'description',
                    content: metaDescription,
                },
                {
                    property: 'og:title',
                    content: title,
                },
                {
                    property: 'og:description',
                    content: metaDescription,
                },
                {
                    property: 'og:image',
                    content: siteOrigin + image,
                },
                {
                    name: 'og:image:width',
                    content: 1200,
                },
                {
                    name: 'og:image:height',
                    content: 630,
                },
                {
                    property: 'og:type',
                    content: 'website',
                },
                {
                    name: 'twitter:card',
                    content: 'summary',
                },
                {
                    name: 'twitter:creator',
                    content: site.siteMetadata.author,
                },
                {
                    name: 'twitter:title',
                    content: title,
                },
                {
                    name: 'twitter:description',
                    content: metaDescription,
                },
                {
                    name: 'google-site-verification',
                    content: googleSiteVerification,
                },
            ].concat( meta )}
        />
    );
}

SEO.defaultProps = {
    lang: 'en',
    meta: [],
    description: '',
};

export default SEO;
