import React, { useEffect, useLayoutEffect, useContext } from 'react';

import { PageWrapperContext } from './page-wrapper';

const Page = ( { children, transitionStatus } ) => {

    const pageWrapperContext = useContext( PageWrapperContext );

    useEffect( () => {

        if ( transitionStatus === 'entering' ) {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        }

        if ( transitionStatus === 'entered' ) {
            pageWrapperContext.setPageHeight( document.body.clientHeight );
        }

    }, [ transitionStatus ] );

    useLayoutEffect( () => {
        pageWrapperContext.setPageHeight( document.body.clientHeight );
    } );

    return <section className={`content ${transitionStatus}`}>{children}</section>;
};

export default Page;
